<template>
  <div class="app-container">
    <el-row class="margin-bottom-20">
      <el-col :lg="16" :xs="16" :offset="0">
        <el-input prefix-icon="el-icon-search" size="mini" placeholder="Correo, Teléfono, Nombre" v-model="searchForm.query"
          v-on:change="handleSearchFilter()" clearable></el-input>
      </el-col>
      <el-col :lg="2" :xs="8" :offset="0">
        <el-button @click.stop.prevent="handleSearchFilter" class="margin-left-10" size="mini" type="primary"
          icon="el-icon-search">Buscar</el-button>
      </el-col>
      <el-col :lg="2" :xs="8" :offset="0">
        <el-button class="margin-left-10" @click.stop.prevent="handleClean" size="mini" type="warning"
          icon="el-icon-delete">Limpiar</el-button>
      </el-col>
      <el-col :lg="2" :xs="8" :offset="0">
        <el-button
          @click.stop.prevent="handleExport"
          type="success"
          icon="el-icon-bottom"
          size="mini"
        >Exportar</el-button>
      </el-col>
    </el-row>
    <el-row>
      <!--<el-col class="grid-content" style="display: flex; justify-content: center;" :lg="4" :md="4" :sm="8" :xs="24">
          <el-select v-model="searchForm.activeSubscription" clearable v-on:change="handleSearchFilter()" size="large" placeholder="Suscripción activa">
            <el-option
              v-for="option in activeOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value">
            </el-option>
          </el-select>
      </el-col>-->
      <el-col style="display: flex; justify-content: center;" :lg="16" :md="16" :sm="14" :xs="24" justify="end">
        <div class="grid-content bg-purple">
          <span class=" margin-filter">Registros por fecha</span>
          <el-date-picker
            v-model="filterDateRegisterRange"
            v-on:change="handleFilterDateRegister()"
            size="mini"
            type="daterange"
            align="right"
            unlink-panels
            format="dd-MM-yyyy"
            range-separator="a"
            start-placeholder="Fecha inicial"
            end-placeholder="Fecha final"
            :picker-options="pickerOptions">
          </el-date-picker>
        </div>
      </el-col>

    </el-row>
    <!---- Paginacion -->
    <el-row type="flex" justify="end">
      <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
        :total="parseInt(totalPages)">
      </el-pagination>
    </el-row>
    <!---- Tabla de Usuarios ----->
    <el-table size="mini" v-loading="loading" :data="list" element-loading-text="Loading" height="600" fit border
      highlight-current-row style="width: 100%">
      <el-table-column label="Correo" fixed align="center" min-width="160">
        <template slot-scope="scope">
          <span>{{ scope.row.email !== undefined ? scope.row.email : 'N/D' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="ID" width="120" align="center">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" placement="top">
            <div slot="content">{{ scope.row._id !== undefined ? scope.row._id : 'No disponible' }}</div>
            <span>{{ scope.row._id !== undefined ? scope.row._id.toString().substr(-4) : 'N/D' }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="Nombre" align="center" min-width="200">
        <template slot-scope="scope">
          <span>{{ scope.row.name !== undefined ? scope.row.name : 'N/D' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Teléfono" align="center" width="120">
        <template slot-scope="scope">
          <span>{{ scope.row.phone !== undefined ? scope.row.phone : 'N/D' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Suscripciones activas" align="center" width="150">
        <template slot-scope="scope">
          <el-tag  v-if="scope.row.activeSubscription"  size="small" type="success">{{ validateStatus(scope.row.activeSubscription) }}</el-tag>
          <el-tag  v-else size="small" type="info">{{ validateStatus(scope.row.activeSubscription) }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Ha Donado" align="center" width="120">
        <template slot-scope="scope">
          <el-tag  v-if="scope.row.hasDonated"  size="small" type="success">{{ validateStatus(scope.row.hasDonated) }}</el-tag>
          <el-tag  v-else size="small" type="info">{{ validateStatus(scope.row.hasDonated) }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Teléfono Verificado" align="center" width="160">
        <template slot-scope="scope">
          <el-tag  v-if="scope.row.isVerifiedPhone"  size="small">{{ validateVerification(scope.row.isVerifiedPhone) }}</el-tag>
          <el-tag  v-else size="small" type="info">{{ validateVerification(scope.row.isVerifiedPhone) }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Correo Verificado" align="center" width="160">
        <template slot-scope="scope">
          <el-tag  v-if="scope.row.isVerifiedEmail"  size="small">{{ validateVerification(scope.row.isVerifiedEmail) }}</el-tag>
          <el-tag  v-else size="small" type="info">{{ validateVerification(scope.row.isVerifiedEmail) }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="ID Stripe" align="center" width="120">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" placement="top">
            <div slot="content">{{ scope.row.customerIdStripe !== undefined ? scope.row.customerIdStripe : 'No disponible' }}</div>
            <span>{{ scope.row.customerIdStripe !== undefined ? scope.row.customerIdStripe.toString().substr(-6) : 'N/D' }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="Completado" align="center" width="120">
        <template slot-scope="scope">
          <el-tag  v-if="scope.row.isComplete"  size="small" type="success">{{ validateStatus(scope.row.isComplete) }}</el-tag>
          <el-tag  v-else size="small" type="info">{{ validateStatus(scope.row.isComplete) }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Género" align="center" width="120">
        <template slot-scope="scope">
          <span>{{ scope.row.gender !== undefined ? scope.row.gender : 'N/D' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Avatar" align="center" width="120">
        <template slot-scope="scope">
          <el-image
            style="width: 50px; max-height: 100px"
            v-if = "scope.row.avatar"
            class="el-upload-list__item-thumbnail"
            :src="`${pathServer + scope.row.avatar }`"
            alt=""
            fit="scale-down"
            :preview-src-list="[pathServer + scope.row.avatar]">
          </el-image>
          <span v-if = "!scope.row.avatar">N/D</span>
        </template>
      </el-table-column>
      <el-table-column label="Intereses" align="center" width="160">
        <template slot-scope="scope">
          <span>
            <template v-if="scope.row.interests !== undefined">
              <ul style="text-decoration: none; list-style: none">
                <li v-for="(interest, index) in scope.row.interests" :key="index">
                {{ interest }}
                </li>
              </ul>
            </template>
            <template v-else>
              {{ 'N/D' }}
            </template>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Fecha de Nacimiento" align="center" width="160">
        <template slot-scope="scope">
          <span v-if="scope.row.birthdate">{{ scope.row.birthdate | formatDate }}</span>
          <span v-else> {{ 'N/D' }} </span>
        </template>
      </el-table-column>
      <el-table-column label="Fecha de Registro" width="180" align="center">
        <template slot-scope="scope">
          <span >{{ scope.row.created_at | formatDateTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Suscripciones" align="center" width="160">
        <template slot-scope="scope">
          <el-button type="primary" @click="handleGoAndSearchSubscriptions(scope.row._id )" size="mini" >Ver Suscripciones</el-button>
        </template>
      </el-table-column>
      <el-table-column label="Pagos" align="center" width="160">
        <template slot-scope="scope">
          <el-button type="success" @click="handleGoAndSearchContributions(scope.row._id )" size="mini" >Ver Pagos</el-button>
        </template>
      </el-table-column>
      <el-table-column label="Logros" align="center" width="160">
        <template slot-scope="scope">
          <el-button type="warning" @click="handleGoAndSearchAchievements(scope.row._id )" size="mini" >Ver Logros</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { search, getPage, exportExcel } from '@/api/users.js'
const confElement = document.getElementById('config')
const conf = JSON.parse(confElement.innerHTML)
const urlServer = conf.baseApi
export default {
  name: 'Users',
  data () {
    return {
      pathServer: urlServer,
      searchForm: {
        query: '',
        initDate: '',
        endDate: ''
      },
      filterDateRegisterRange: '',
      totalPages: '',
      currentPage: '',
      loading: false,
      sizePerPage: 25,
      list: [],
      pickerOptions: {
        shortcuts: [{
          text: 'Semana pasada',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Mes pasado',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '3 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '6 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '9 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 270)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '1 Año atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
            picker.$emit('pick', [start, end])
          }
        }]
      }
    }
  },
  mounted () {
    this.handleSearchFilter()
  },
  methods: {
    validateVerification (isVerified) {
      return isVerified ? 'Verificado' : 'Sin Verificar'
    },
    // --- data search ----
    async handleSearchFilter () {
      this.loading = true
      await search(this.searchForm)
        .then((response) => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleChangeItemPage (val) {
      this.loading = true
      this.sizePerPage = val
      await getPage(this.currentPage, val, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleCurrentChange (page) {
      this.currentPage = page
      this.loading = true
      await getPage(page, this.sizePerPage, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    handleGoAndSearchSubscriptions (id) {
      this.$router.push({ name: 'stripe.subscriptions', params: { _id: id } })
    },
    handleGoAndSearchContributions (id) {
      this.$router.push({ name: 'stripe.contributions', params: { _id: id } })
    },
    handleGoAndSearchAchievements (id) {
      this.$router.push({ name: 'achievements.users', params: { _id: id } })
    },
    handleClean () {
      this.filterDateRegisterRange = []
      this.searchForm.query = ''
      this.handleSearchFilter()
    },
    async handleExport () {
      this.loading = true
      await exportExcel(
        this.searchForm
      ).then(response => {
        const url = window.URL.createObjectURL(new Blob([response]))
        const link = document.createElement('a')
        link.href = url
        const name = 'Reporte-de-Suscripciones-Paypal-' + new Date() + '.xlsx'
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
        console.log('Archivo Exportado')
      })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    validateStatus (status) {
      return status ? 'Si' : 'No'
    },
    handleFilterDateRegister () {
      if (this.filterDateRegisterRange) {
        this.searchForm.initDate = this.filterDateRegisterRange[0]
        this.searchForm.endDate = this.filterDateRegisterRange[1]
        this.handleSearchFilter()
      } else {
        this.searchForm.initDate = ''
        this.searchForm.endDate = ''
        this.handleSearchFilter()
      }
    }
  }
}
</script>

<style lang="css">

</style>
