import request from '@/utils/request'
export function search (params) {
  return request({
    url: '/api-manager/user/search',
    method: 'post',
    params
  })
}
export function getPage (page, noitems, params) {
  return request({
    url: '/api-manager/user/search?page=' + page + '&item=' + noitems,
    method: 'post',
    params
  })
}

export function getUsers (id) {
  return request({
    url: `/api-manager/user/${id}`,
    method: 'get'
  })
}

export function createUsers (data) {
  return request({
    url: '/api-manager/user',
    method: 'post',
    data
  })
}

export function updateUsers (data, id) {
  return request({
    url: `/api-manager/user/${id}`,
    method: 'put',
    data
  })
}

export function deleteUsers (id) {
  return request({
    url: `/api-manager/user/${id}`,
    method: 'delete'
  })
}

export function exportExcel (data) {
  return request({
    url: '/api-manager/user/export',
    method: 'post',
    responseType: 'blob',
    data
  })
}
